<template>
    <div id="firmaekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-5"
                            label="Bölge Adı:"
                            label-for="input-5">
                            <b-form-input
                            id="input-5"
                            v-model="form.bolge_ad"
                            type="text"
                            required
                            placeholder="Bölgenin adını girin"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-1"
                            label="Belediye Seçin"
                            label-for="input-1">
                            <b-form-select required @change="kontrol()" id="input-1" class="mb-3" v-model="form.user_id" :options="users"></b-form-select>
                        </b-form-group>
                        <b-button block v-if="!$route.params.id" :disabled="!form_durum" type="submit" variant="primary" class="float-right mb-5">Ekle</b-button>
                        <b-button block v-if="$route.params.id" :disabled="!form.user_id || !form.bolge_ad" type="submit" variant="primary" class="float-right mb-5">Düzenle</b-button>
                    </b-form>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>

//import Swal from 'sweetalert2';
export default {
    data() {
        return{
            //form: [],
            form_durum: false,
        }
    },

    methods : {
        ekle(){
            if(this.$route.params.id){
                console.log({
                    bolge_ad: this.form.bolge_ad,
                    bolge_belediye: this.form.user_id,
                    bolge_id : this.$route.params.id
                });
                this.$store.dispatch("bolgeEdit", {
                    bolge_ad: this.form.bolge_ad,
                    bolge_belediye: this.form.user_id,
                    bolge_id : this.$route.params.id
                }).then(r => {
                    if(r.status){
                        this.$router.push("/Bolgeler")
                    }
                })
            }else{
                this.$store.dispatch("bolgeAdd", {
                    bolge_ad: this.form.bolge_ad,
                    bolge_belediye: this.form.user_id,
                }).then(r => {
                    if(r.status){
                        this.$router.push("/bolgeler")
                    }
                })
            }   
        },
        kontrol(){
            // this.$store.dispatch("bolgeToplayiciKontrol", {bolge_belediye: this.form.user_id}).then(r => {
            //     if(r.status){
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'Oops...',
            //             text: r.message,
            //         });
            //         this.form.user_id = null;
            //         this.form_durum = false;
            //     }else{
            //         this.form_durum = true;
            //     }
            // })
            this.form_durum = true;
        }
    },

    computed: {
        form(){
            var deger = [];
            if(this.$route.params.id){
                deger = this.$store.state.bolgeler.filter(c => c.bolge_id == this.$route.params.id)[0]
            }
            return deger;
        },
        users(){
            var data = this.$store.state.users.map(c => {
                var data = [];

                if(c.user_rutbe == 2){
                    data = {
                        value: c.user_id,
                        text: c.user_adsoyad
                    }
                }
                return data;
            }).filter(c => c.value);

            // if(this.$route.params.id){
            //     data.unshift({
            //         value: this.form.user_id,
            //         text: this.form.user_adsoyad
            //     });
            // }
            return data;
        }
    }
}
</script>